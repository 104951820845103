import React from 'react'
import './customerSupport.css'
const CustomerSupport = () => {
  return (
    <div className='customerSupport'>
      <div className='customerSupport1'>
        <div>
            <h3>Email</h3>
            <p>sen@shinelogics.com</p>
        </div>
        <hr />
        <div>
            <h3>Mobile</h3>
            <p>+91 9500037221</p>
        </div>
      </div>
    </div>
  )
}

export default CustomerSupport
